.App {
  border-radius: 10px;
}

/* ----------------GLOVAL STYLES------------------ */

/* background Flowers */

.baseF {
  background-image: url("/src/images/fondoWeb.png");
  background-size: 100vw ;
  background-repeat: repeat;
}

/* container */
.subrayado {
  border-bottom: 5px solid #9464F4;
}

.media1000 {
  width: 1000px;
}

.flex {
  display: flex;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.center {
  justify-content: center;
  align-items: center;
}

.btw {
  justify-content: space-between;
  align-items: center;
}

.space-around {
  justify-content: space-around;
  align-items: center;
}

.gap-40 {
  gap: 40px;
}

.gap-20 {
  gap: 20px;
}

.gap-10 {
  gap: 10px;
}

.gap-5 {
  gap: 5px;
}

.padding-10 {
  padding: 10px;

}

.padding-20 {
  padding: 20px;

}

.padding-40 {
  padding: 40px;

}

/* MARGIN */

.margin-10 {
  margin: 10px;
}

.margin-20 {
  margin: 20px;
}

.margin-40 {
  margin: 40px;
}

.mt-40 {
  margin-top: 40px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-100 {
  margin-bottom: 100px;
}

/* --------------------CARD-----------------------*/

/* reversible card */
.card {
  width: 300px;
  height: 200px;
  perspective: 1000px;
}

.card-inner {
  width: 100%;
  height: 100%;
  position: relative;
  transform-style: preserve-3d;
  transition: transform 0.999s;
}

.card:hover .card-inner {
  transform: rotateY(180deg);
}

.card-front,
.card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

.card-front {
  background-color: #6A2C70;
  color: #fff;
  display: flex;
  align-items: center;
  border: 10px solid #6A2C70;
  border-radius: 10px;
  justify-content: center;
  font-size: 24px;
  transform: rotateY(0deg);
}

.card-back {
  background-color: #F08A5D;
  color: #fff;
  display: flex;
  align-items: center;
  border: 10px solid #F08A5D;
  border-radius: 10px;
  justify-content: center;
  font-size: 24px;
  transform: rotateY(180deg);
}

/* -------------------CARD 2------------------------ */

/* reversible card2 */
.card2 {
  width: 300px;
  height: 200px;
  perspective: 1000px;
}

.card2-inner {
  width: 100%;
  height: 100%;
  position: relative;
  transform-style: preserve-3d;
  transition: transform 0.999s;
  
}

.card2:hover .card2-inner {
  transform: rotateY(180deg);
  
}

.card2-front,
.card2-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  padding: 10px;
  
}

.card2-front {
  background-color: #a177f5;
  color: #9464F4;
  display: flex;
  align-items: center;
  border: 10px solid transparent;
  border-image: linear-gradient(to right, #9464F4, #a97ffd, #9464F4);
  border-image-slice: 1;
  justify-content: center;
  font-size: 24px;
  transform: rotateY(0deg);
  left: -32px;
}

.card2-back {
  background-color: #fff;
  color: #9464F4;
  display: flex;
  align-items: center;
  border: 10px solid #9464F4;
  justify-content: center;
  font-size: 24px;
  transform: rotateY(180deg);
}

/* ------------------------------------------------- */

.boxShadow {
  box-shadow: 20px 20px 60px #bebebe,
                -20px -20px 60px #ffffff;
}

.borderCard {
  background-color: #fff;
  box-shadow: 20px 20px 60px #bebebe,
                -20px -20px 60px #ffffff;
}

.borderCardViolet {
  background-color: #fff;
  box-shadow: 20px 20px 60px #795ab3,
                -20px -20px 60px #ffffff;
}

.borderCard2 {
  border-radius: 50px;
  background: lightgrey;
  -webkit-box-shadow: inset 0px 1px 122px 2px rgba(0,0,0,0.28);
-moz-box-shadow: inset 0px 1px 122px 2px rgba(0,0,0,0.28);
box-shadow: inset 0px 1px 122px 2px rgba(0,0,0,0.28);
}

.borderCardOpacity {
  background-color: #ffffffc9;
  border-radius: 50px;
  box-shadow: 20px 20px 60px #bebebe,
                -20px -20px 60px #ffffff;
}

.border-radius {
  border-radius: 10px;
}

.br-15 {
  border-radius: 15px;
}

.br-30 {
  border-radius: 30px;
}

.br-50 {
  border-radius: 50px;
}

.bg-ligth {
  background-color: #F0EAF9;

}

.bg-black {
  background-color: #000;
}

.bg-purple {
  background-color: #9464F4;
}


/* WIDTH */

.headerImg {
  width: 100%;
  height: 90vh;
  border-radius: 10px;
  margin: 10px;
}

.w-100 {
  width: 100%;
}

.w-80 {
  width: 80%;
}

.w-60 {
  width: 60%;
}

.w-40 {
  width: 40%;
}

.w-400 {
  width: 400px;
}

/* BUTTON */

.myBtn {
  position: relative;
  display: inline-block;
  padding: 5px 10px;
  text-align: center;
  font-size: 14px;
  letter-spacing: 1px;
  text-decoration: none;
  color: #8D50FF;
  background-color: #8d50ff00;
  cursor: pointer;
  transition: ease-out 0.8s;
  border: 2px solid #8D50FF;
  border-radius: 10px;
  box-shadow: inset 0 0 0 0 #8D50FF;
} .myBtn:hover {
  color: white;
  box-shadow: inset 0 -100px 0 0 #8D50FF;
} .myBtn.active {
  color: white;
  background-color:#8D50FF;
}

/* <li> */

.liNone {
  list-style-type: none;
}


/* ---------------------------------------------------------------------- */

/* TEXT  */
.whiteColor {
  color: #fff;
}

.blackColor {
  color: #000;
  z-index: 3;
}

.pinkColor {
  color: #9464F4 ;
}

.blueColor {
  color: #80b3ff ;
}

.purpleColor {
  color: #9464F4;
}

.text-center {
  text-align: center;
}

/* ----------- */

/* Font */
@font-face {
  font-family: 'TitlesFont';
  src: url('./fonts/Merienda-VariableFont_wght.ttf') format('truetype');
}

@font-face {
  font-family: 'TextFont';
  src: url('./fonts/Belleza-Regular.ttf') format('truetype');
}

button {
  font-family: 'TitlesFont', sans-serif;
}

p {
font-size: 20px;
font-family: 'TextFont', sans-serif;
margin-left: 15px;
margin-right: 15px;
}


.h1-60 {
  font-size: 60px;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'TitlesFont', sans-serif;
  margin-left: 15px;
  margin-right: 15px;
  color: #9464F4;
}
/* ----------- */



@media (max-width: 1100) {
  .media1000 {
    width: 90%;
  }
}

@media (max-width: 700px) {
  h1 {
    font-size: 24px;
  }

  p {
    font-size: 18px;
    }
}

@media (max-width: 610px) {
  

  /*  */
  .card2 {
    width: 250px;
    height: 200px;
  }

  .card2-back {
    font-size: 20px;
  }

  

}

