
.boxNutrition {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 100px;
    justify-content: center;
    align-items: center;
}

.h1HeaderNutrition {
    font-size: 50px;
}

.headerNutrition {
    width: 100%;
    height: 90vh;
    background-image: url("../../images//nutraceuticaHeader.jpg");
    background-size: cover;
    background-position:right;
    background-attachment: fixed;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.h1Header {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 500px;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
    height: 300px;
    border-radius: 30px;
    margin: 50px;
    background-color: rgba(0, 0, 0, 0.342);
    text-align: center;
}

.sectionNutrition {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px;
}

.section1 {
    display: flex;
    justify-content: center;
    width: 1500px;
    height: 300px;
    align-items: center;
    gap: 100px;
}

.description1 {
    width: 500px;
}

.img1 {
    border-radius: 30px;
    width: 300px;
    box-shadow: 20px 20px 60px #bebebe,
                -20px -20px 60px #ffffff;
}

.boxDepresion {
    width: 1000px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 100px;
    box-shadow: 20px 20px 60px #bebebe,
                -20px -20px 60px #ffffff;
    padding-top: 50px;
    padding-bottom: 50px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 30px;
    background-color: #fff;
}

.imgDepresion {
    width: 500px;
    border-radius: 30px;
    box-shadow: 20px 20px 60px #bebebe,
                -20px -20px 60px #ffffff;
}

.pDepresion {
    width: 500px;
    padding: 30px;
}

.section2 {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.section2 div {
    width: 80%;
}

.section3 {
    width: 100%;
}

.divDetailsNutrition {
    width: 1000px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
}

/* ----------------------- */

@media (max-width: 1230px) {
    .boxDepresion {
        flex-direction: column;
        gap: 20px;
    
    }

    .divDetailsNutrition {
        width: 95%;
    }
}

@media (max-width: 1000px) {
    
    .section2 div {
        width: 950%;
    }

    .section1 {
        flex-direction: column;
        gap: 20px;
    }

}

@media (max-width: 800px) {
    .section1 {
        flex-direction: column;
        gap: 20px;
    }

}

@media (max-width: 610px) {
    .h1HeaderNutrition {
        font-size: 35px;
    }

    .imgDepresion {
        width: 350px;
    }

    .pDepresion {
        width: 300px;

    }

    .section1 {
        margin-top: 100px;
        margin-bottom: 100px;
    }

    .description1 {
        width: 300px;;
    }

    .section1 {
        gap: 50px;
    }

}