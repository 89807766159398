.imgHeaderMassage {
    background-image: url("../../images/massageP.png");
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    z-index: 1; 
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.textHeader {
    width: 50%;
}


.boxMiniCard {
    position: relative;
    bottom: 100px;
}

.titleBoxMiniCard {
    position: relative;
    top: 100px;
}

.boxBeneficios {
    text-align: center;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    padding: 50px;
}

.cardBeneficios:hover {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);

}

.sectionMassage {
    cursor: pointer;
}

.summaryMassage:hover {
    background-color: #7a52ca;
}

.boxMiniCards {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 40px;
}

/* ----------------- */
@media (max-width: 1400PX) {
    .cardBeneficios {
        width: 300px;
    }

    .titleBoxMiniCard {
        top: 0px;
        margin-bottom: 40px;
        margin-top: 200PX;
    }

    .boxMiniCard {
        bottom: 0px;
        margin-top: 40px;
        margin-bottom: 200px;
    }
}

@media (max-width: 1140PX) {

    .boxBeneficios {
        grid-template-columns: repeat(2, 1fr);
    }

}

@media (max-width: 900px) {
    
    .boxBeneficios {
        grid-template-columns: repeat(1, 1fr);
    }
    

    .textHeader {
        width: 80%;
    }
}

@media (max-width: 655px) {
    .sectionMassage {
        width: 80%;
    }
    
}