.contentHeader {
    display: flex;
    flex-direction: column;
}

.empyHeader {
    height: 60px;
    color: #fff;
}

.boxHeader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    display: flex;
    justify-content:space-between;
    align-items: center;
    height: 60px;
    background-color: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
    border-radius: 15px;
    border: 1px solid #8D50FF;
    margin-top: 1px;
    margin-left: 9px;
    margin-right: 9px;
}

.imgLogoSimona {
    margin-left: 30px;
    display: block;
    width: 150px;
    
} .imgLogoSimona:hover {
    transform: scale(1.3);
    transition: 0.5s;
}


.navBar {
    display: grid;
    grid-template-columns: repeat(7, 1fr); 
    gap: 10px;
    padding-right: 20px;
    height: 120px;
    overflow: hidden;
    align-items: center;

}

.menuOpen .navBar {
    height: 685px;
    overflow: visible;
}

.menuButton {
    display: none;
}
    
.socialNetworkIcons {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin-right: 30px;
    
}

.line {
    display: none;
}

.iconSocial {
    width: 40px;
}

.iconSocial:hover {
    transform: scale(1.2);
    transition: 0.5s;
}



/* ------Responsive------ */

@media (max-width: 1503px) {
    .marginTop {
        margin-top: 20px;
    }

    .linkLi {
        font-size: 30px;
        width: 70%;
    }

    .linkLi:hover {
        height: 50px;
        background: rgb(255,255,255);
        background: radial-gradient(circle, #8d50ffcb 1%, rgba(148,187,233,0) 80%);
        color: #fff;
    }
    
    .marginTopMenu {
        margin-top: 20px;
    }

    .navBar {
        
        align-items: center;
        height: 0;
        flex-direction: column; 
        position: absolute;
        top: 50px;
        left: 0;
        right: 0;
        backdrop-filter: blur(15px);
        border-radius: 40px;
    }

    .boxLinkHeader {
        width: 250px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #d2baff9a;
    }

    .line {
        display: inline;
        width: 80%;
        border: 1px solid #8D50FF;
    }

    .menuButton {
    display: block;
    cursor: pointer;
    transition: 0.5s;
    }

    .menuOpen .navBar {
    display: flex;
    background: rgba(255, 255, 255, 0.281);

    }
}

@media (max-width: 1420px) {
    .responsiveBtn {
      background-color: #2c00855d;
      color: #fff;
      width: 200px;
      height: 30px;
      font-size: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

@media (max-width: 500px) {
    .imgLogo {
        display: none;
    }

    .menuButton {
        margin-left: 30px;
    }
}

