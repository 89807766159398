.widthMiniCard {
    width: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 20px;
}

.pMiniCard {
    text-align: center;

}

@media (max-width: 850px) {
    .widthMiniCard {
        width: 85%;
    }
}