.boxFooter {
    display: flex;
    justify-content:space-between;
    align-items: center;
    width: 100%;
    height: 250px;
    background-color: #fff;
    border-radius: 15px;
    border: 1px solid #8D50FF;
    margin-bottom: 20px;
    padding-bottom: 20px ;
}

.section1Footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 80%;
    width: 300px;
    gap: 10px;
}

.footerLink {
    text-decoration: none;
    color: #8D50FF;
}


@media (max-width: 768px) {
    .boxFooter {
        flex-direction: column;
        height: max-content;
    }
    .section1Footer {
        width: 100%;
        height: 50%;
    }
}