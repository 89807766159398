.page-contact {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 40px;
    justify-content: center;
    align-items: center;
}

.headerContact {
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    align-items: center;
    padding: 30px;
}

.imgSimonaSize {
    width: 300px;
    border-radius: 50%;
    background-color: #9162ff93;
}

.contact-details {
    display: flex;
    margin-top: 20px;
    gap: 50px;
}

.border33 {
    border: 3px solid #9162ff;
    width: 100%;
}

.leaflet-container {
    border-radius: 50px;
}

@media (max-width: 750px) {
    .contact-details {
        flex-direction: column;
        gap: 20px;
    }
}