


/* ---------- Paleta de colores ---------- */
.color1 { color: #ffc2ce };
.color2 { color: #80b3ff };
.color3 { color: #fd6e8a };
.color4 { color: #a2122f };
.color5 { color: #693726 };
/* --------------------------------------- */

.colorTexto { color: #9464F4 };
.colorFondo { color: #9464f440 };