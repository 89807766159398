.boxStart {
    position: relative;
    gap: 20px;
    padding-bottom: 100px;
}

.startHeader {
    display: flex;
    gap: 100px;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.8);
    background-image: url("../../images/planta.png");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-position: center;
    height: 90vh;
    width: 100%;
}

.imagesHeaderStart {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
}

.noOpacity {
    position: relative;
    z-index: 1;
}

.blunt {
    border-radius: 15px;
    background-color: #ffffff72;
}

.welcomeStart {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 700px;
    padding: 20px;
    border-radius: 15px;
    background-color: #ffffffb9;
    box-shadow: 10px 10px 20px rgba(117, 117, 117, 0.2);
    

}

/* Carousel */

.carousel {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
} 
/* ----------- */

.logoShakti {
    width: 600px;
    float: left;
    box-shadow: 10px 10px 20px rgba(117, 117, 117, 0.2);
    background-color: #ffffff72;
    border-radius: 10%;

}

.imgSimona {
    border-radius: 50%;
    width: 450px;
    box-shadow: 10px 10px 20px rgba(117, 117, 117, 0.449);
}

.title {
    font-size: 40px;
    
}

.titleSmall {
    font-size: 30px;
}

.textSmall {
    margin-left: 20px;
    width: 560px;
}

.responsive {
    width: 100%;
    height: auto;
}


.flex {
    display: flex;
    align-items: center;
}

.column {
    flex-direction: column;
}

.margin {
    margin-left: 30px;
    margin-right: 30px;
}

.paddingTop {
    padding-top: 50px;
}

.center {
    justify-content: center;
    
}

.bold {
    font-weight: bold;
}

.coachingHeader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 50px;
}


@media (max-width: 1300px) {
    .startHeader {
        flex-direction: column;
        height: 100%;
    }

    .welcomeStart {
        margin-bottom: 40px;
    }

    .welcomeDescription {
        width: 90%;
    }

    .imagesHeaderStart {
        flex-direction: column-reverse;
    }

    .imgSimona {
        margin-top: 100px;
    }

    .startHeader::before {
        height: 100%;
    }

    
}


@media (max-width: 840px) {
    .welcomeStart {
        margin-bottom: 0;
        border-radius: 50px;
        width: 90%;
    }

    .logoShakti {
        width: 90%;
    }
}

@media (max-width: 780px) {
  .title {
    text-align: center;
  }
}

@media (max-width: 600px) {
    .textSmall {
        width: 95%;
    }

    .imgSimona {
        width: 300px;
    }

    .logoShakti {
        width: 97%;
    }
}
