.boxCardReviews {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 500px; 
}

/* Asegurar que el contenido del slider se ajuste correctamente */
.boxCardReviews .slick-list,
.boxCardReviews .slick-track,
.boxCardReviews .slick-slide {
    width: 500px;
}

@media (max-width: 600px) {
    .boxReviews {
        width: 80%;
    }

    .slick-prev,
    .slick-next {
        display: none; /* Ocultar flechas en pantallas pequeñas */
    }

    .boxCardReviews {
        width: 90%;
    }

    .boxCardReviews .slick-list,
    .boxCardReviews .slick-track,
    .boxCardReviews .slick-slide {
        width: 90%;
    }
}
