.boxCardDefinition {
    display: flex;
    flex-direction: column;
    margin: 40px;
    justify-content: center;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
}


.imgCardDefinition {
    width: 320px;
    height: 320px;
    border-radius: 50%; 
    margin-left: 50px;
    background-size:auto; 
    background-position: center;
    background-repeat: no-repeat;
} .imgCardDefinition:hover {
    transform: scale(1.1);
    transition: 0.5s;

}

.cardDescription {
    padding: 15px;
    width: 50%;
}

.cardContainer {
    display: flex; 
    justify-content: center;
    align-items: center;
    gap: 60px;
    width: 1200px;
}

.btnCardDefinition {
    position: relative;
    left: 40px;
    
}

.borderDescription {
    border-radius: 10px;
    background-color: #fff;
    
}

@media (max-width: 1350px) {

    .cardDescription {
        width: 100%;
    }

    .cardContainer {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 50px;
        width: 100%;
    }

    .imgCardDefinition {
        margin-right: 50px;
    }
}

@media (max-width: 700px) {
    .boxCardDefinition {
        margin: 10px;
    }

    .cardContainer {
        width: 100%;
    }
}