.boxPageCoaching {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 80px;
}

.headerPageCoaching {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 90vh;
    width: 100%;
    background-image: url("../../images/hombre-cima-montana-brazos-abiertos-2800647.webp");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.h1HeaderPageCoaching {
    padding: 30px;
    width: 600px;
    margin: 60px;
    text-align: center;
    padding: 20px;
    border-radius: 15px;
    background-color: #ffffff72;
    box-shadow: 10px 10px 20px rgba(117, 117, 117, 0.2);
}

.boxDescriptionCoaching {
    display: flex;
    flex-direction: column;
    width: 1000px;
    gap: 40px;
}

.boxImgS {
    display: flex;


}

.boxDiferences {
    display: flex;
}

.miniBoxDiferences {
    display: flex;
    flex-direction: column;
}

.imgHeaderPageCoaching {
    border-radius: 8px;
    margin: 40px;
    width: 600px;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75); 
}

.section1Coaching {
    display: flex;
    flex-direction: column;
    width: 1000px;
    justify-content: center;
    align-items: center;
    text-align: center;
}


.ulHeaderPageCoaching {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    margin: 40px;
    width: 100%;
}

.imgDiferences {
    width: 600px;
    border-radius: 10px;
}

.imgS1 {
    width: 455px;
    border-radius: 10px;
}

.boxWhot {
    width: 1000px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.boxDetailsPageCoaching {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 90px;
    justify-content: center;
}

.detailsPageCoaching {
    width: 100%;
    box-shadow: 20px 20px 60px #bebebe,
                -20px -20px 60px #ffffff;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px;
    background-color: #ffffff;
}

.summaryPageCoaching {
    display: flex;
    gap: 40px;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    background-color: #9464F4;
    border-radius: 10px;
    padding-left: 60px;
    padding-right: 60px;
}

.flecha {
    width: 20px;
    margin-right: 30px;
}

.textDetails { 
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px;
    border-radius: 15px;
}

.textDetails p { 
    color: #000000;
}

/* ---------------- */

.card {
    width: 400px;
    height: 360px;
    perspective: 1000px;
}

.card-inner {
width: 100%;
height: 100%;
position: relative;
transform-style: preserve-3d;
transition: transform 0.999s;
}

.card:hover .card-inner {
transform: rotateY(180deg);
}

.card-front,
.card-back {
position: absolute;
width: 100%;
height: 100%;
backface-visibility: hidden;
}

.card-front {
background-color: #9464F4;
box-shadow: 20px 20px 60px #bebebe,
            -20px -20px 60px #ffffff;
color: #fff;
display: flex;
align-items: center;
border: 10px solid #9464F4;
border-radius: 10px;
justify-content: center;
font-size: 24px;
transform: rotateY(0deg);
}

.card-back {
background-color: #ffffff;
box-shadow: 20px 20px 60px #bebebe,
            -20px -20px 60px #ffffff;
color: #9464F4;
display: flex;
align-items: center;
border: 10px solid #ffffff;
border-radius: 10px;
justify-content: center;
font-size: 24px;
transform: rotateY(180deg);
}

.boxVideoCoaching {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.videoCoaching {
    width: 600px;
    height: 380px;
    margin-top: 30px;
    margin-bottom: 100px;
    border-radius: 15px;
}

.liNonePoint {
    list-style-type: none;
    margin: o;
    padding: 0;
}


  

/* ---------------- */


@media (max-width: 1350px) {
    .boxDiferences {
        flex-direction: column;
    }

    .miniBoxDiferences {
        flex-direction: row;
    }

    .imgDiferences {
        width: 910px;

    }
    
    .imgS1 {
        width: 455px;

    }

}

@media (max-width: 1100px) {
    .detailsPageCoaching {
        width: 80%  
    } 

    .boxDescriptionCoaching {
        width: 90%;
    }

    .boxWhot {
        width: 90%;
    }

    .section1Coaching {
        width: 90%;
    
    }
}

@media (max-width: 950px) {
    .boxDiferences {
        width: 90%;
    }

    .imgDiferences {
        width: 100%;
        border-radius: 10px;
    }

    .miniBoxDiferences {
        width: 100º;
    }

    .imgS1 {
        width: 50%;
    }
}

@media (max-width: 680px) {
    .boxVideoCoaching {
        width: 95%;
    }

    .h1Size {
        font-size: 30px;
    }

    .videoCoaching {
        width: 95%;

    }
    .liNonePoint {
        font-size: 11px;
    }

    .ulHeaderPageCoaching {
        justify-content: center;
    }

    .h1HeaderPageCoaching {
        padding: 15px;
        border-radius: 30px;
        width: 90%;
        margin: 30px;
        text-align: center;
    }

   

    .summaryPageCoaching {
        padding-left: 10px;
        padding-right: 10px;
        gap: 20px;
        width: 100%;
    }

    .card {
        width: 300px;

    }
}

